<template>
  <div class="relative bg-white">
    <div class="absolute inset-0">
      <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
    </div>
    <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
      <div class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div class="max-w-lg mx-auto">
          <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            <img class="inline h-8 w-auto sm:h-10" src="@/assets/blockbase_mark_180x180.png" alt="BlockBase logo" />
            Get in touch
          </h2>
          <p class="mt-3 text-lg leading-6 text-indigo-500">
            BlockBase.dev is produced with
            <br>❤️  by Ev Chapman and Ned McClain.
          </p>
          <dl class="mt-8 text-base text-gray-500">
            <div>
              <dt class="sr-only">Postal address</dt>
              <dd>
                <p>New Moon Labs</p>
                <p>800 Copper Road #3042</p>
                <p>Copper Mountain, CO  80443</p>
              </dd>
            </div>
            <div class="mt-6">
              <dt class="sr-only">Phone number</dt>
              <dd class="flex">
                <PhoneIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span class="ml-3">
                  1-877-960-1004
                </span>
              </dd>
            </div>
            <div class="mt-3">
              <dt class="sr-only">Email</dt>
              <dd class="flex">
                <MailIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span class="ml-3">
                  hello@newmoonlabs.com
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
        <div class="max-w-lg mx-auto lg:max-w-none">
          <form v-if="formState === 'ready'" @submit="formSubmit" class="grid grid-cols-1 gap-y-6">
            <div>
              <label for="full_name" class="sr-only">Name</label>
              <input type="text" v-model="contact.name" name="full_name" id="full_name" autocomplete="given-name" required="" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Name" />
            </div>
            <div>
              <label for="email" class="sr-only">Email</label>
              <input id="email" v-model="contact.from" name="email" type="email" autocomplete="email" required="" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Email" />
            </div>
            <div>
              <label for="message" class="sr-only">Message</label>
              <textarea id="message" v-model="contact.body" name="message" rows="4" required="" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Message" />
            </div>
            <div>
              <button type="submit" class="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Submit
              </button>
            </div>
          </form>
          <div v-else-if="formState === 'busy'" class="mt-4 font-bold text-indigo-700">
            Submitting...
          </div>
          <div v-else class="mt-4 font-bold text-indigo-700">
            Thank you! We'll get back to you soon!!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from '@heroicons/vue/outline'

export default {
  components: {
    MailIcon,
    PhoneIcon,
  },
  data () {
    return {
      contact: {},
      formState: 'ready',
    }
  },
  methods:  {
    async formSubmit(e) { 
      e.preventDefault()
      this.formState = 'busy'
      this.contact.form = 'Contact'
      var response = {}
      try {
        response = await this.axios.post('https://forms-api.blockbase.dev/submit/blockbase', this.contact)
      } catch (err) {
        console.log('TK: ERR!', err)
        return
      }
      if (!response.data.success) {
        console.log('TK: ERR!', response.data)
        return
      }
      this.formState = 'done'
    }
  }
}
</script>
